import { useMemo, useState } from "react";
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from "reactstrap";
import ProjectsService from "../Services/ProjectsService";
import { registerLocale } from "react-datepicker";
import nlBE from "date-fns/locale/nl-BE";
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import PersonModel from "../Models/PersonModel";
import { SupplierModel } from "../Models/SupplierModel";

interface ProjectForemanModalProps {
  code: string;
  customForemanIdOrCode: string | null;
  foremen: PersonModel[];
  suppliers: SupplierModel[];
  persons: PersonModel[];
  afterSave: Function;
  afterCancel: Function;
}

const ProjectForemanModal = (props: ProjectForemanModalProps) => {
  const { instance } = useMsal();

  const projectsService = useMemo(
    () => new ProjectsService(instance),
    [instance]
  );

  const [customForemanIdOrCode, setCustomForemanIdOrCode] = useState<
    string | null
  >(props.customForemanIdOrCode);
  const [visible, setVisible] = useState<boolean>(true);

  registerLocale("nlBE", nlBE);

  function visibleToggle() {
    setVisible(!visible);
    props.afterCancel();
  }

  async function projectForemanModalSave() {
    try {
      if (props.code !== null) {
        await projectsService.updateProjectCustomForeman(
          props.code,
          customForemanIdOrCode
        );

        setVisible(false);

        props.afterSave();
      }
    } catch (err) {
      toast.error("Fout bij het ophalen van de data.");
    }
  }

  return (
    <Modal isOpen={visible} toggle={visibleToggle}>
      <ModalHeader toggle={visibleToggle}>
        {props.code} - Ploegleider
      </ModalHeader>
      <ModalBody>
        <form className="form-inline">
          <div className="form-group">
            <label className="my-1 mr-2" htmlFor="projectCustomForemanInput">
              Ploegleider
            </label>
            <select
              className="form-control my-1 mr-sm-2"
              id="projectCustomForemanInput"
              onChange={(e) => setCustomForemanIdOrCode(e.target.value)}
              defaultValue={customForemanIdOrCode ?? ""}
            >
              <option value="null">- Geen ploegleider -</option>
              {props.foremen
                .sort((a, b) => {
                  if ((a.name ?? "") < (b.name ?? "")) {
                    return -1;
                  }
                  if ((a.name ?? "") > (b.name ?? "")) {
                    return 1;
                  }
                  return 0;
                })
                .map((person) => {
                  return (
                    <option value={person.id} key={person.id}>
                      {person.name}
                    </option>
                  );
                })}
              {props.suppliers
                .sort((a, b) => {
                  if ((a.name ?? "") < (b.name ?? "")) {
                    return -1;
                  }
                  if ((a.name ?? "") > (b.name ?? "")) {
                    return 1;
                  }
                  return 0;
                })
                .map((supplier) => {
                  return (
                    <option value={supplier.code ?? ""} key={supplier.code}>
                      {supplier.name}
                    </option>
                  );
                })}
              {props.persons
                .filter((p) => {
                  return props.foremen.find((a) => a.id === p.id) === undefined;
                })
                .sort((a, b) => {
                  if ((a.name ?? "") < (b.name ?? "")) {
                    return -1;
                  }
                  if ((a.name ?? "") > (b.name ?? "")) {
                    return 1;
                  }
                  return 0;
                })
                .map((person) => {
                  return (
                    <option value={person.id} key={person.id}>
                      {person.name}
                    </option>
                  );
                })}
              <option value="kbContracting">KB Contracting</option>
            </select>
          </div>
        </form>
      </ModalBody>
      <ModalFooter>
        <Button color="primary" onClick={projectForemanModalSave}>
          Bewaren
        </Button>{" "}
        <Button color="secondary" onClick={visibleToggle}>
          Sluiten
        </Button>
      </ModalFooter>
    </Modal>
  );
};

export default ProjectForemanModal;
