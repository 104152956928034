import { useCallback, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import GraphService from "../Services/GraphService";
import { User } from "@microsoft/microsoft-graph-types";
import { format, isWeekend, parseJSON } from "date-fns";
import nlBE from 'date-fns/locale/nl-BE';
import { Button } from "reactstrap";
import RidesService from "../Services/RidesService";
import RideModel from "../Models/RideModel";
import RideModal from "../Components/RideModal";
import PlanningService from "../Services/PlanningService";
import ProjectModel from "../Models/ProjectModel";
import PeopleService from "../Services/PeopleService";
import PersonModel from "../Models/PersonModel";

const Rides = () => {

    const { instance } = useMsal();

    const graphService = useMemo(() => new GraphService(instance), [instance]);
    const ridesService = useMemo(() => new RidesService(instance), [instance]);
    const planningService = useMemo(() => new PlanningService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);

    const [currentUser, setCurrentUser] = useState<User>();
    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [month, setMonth] = useState<number>((new Date()).getMonth() + 1);
    const [daysInMonth, setDaysInMonth] = useState<Date[]>();
    const [rides, setRides] = useState<RideModel[]>();
    const [projects, setProjects] = useState<ProjectModel[]>();
    const [users, setUsers] = useState<PersonModel[]>();
    const [showModal, setShowModal] = useState<boolean>(false);
    const [selectedRide, setSelectedRide] = useState<RideModel>();

    const loadRides = useCallback(
        async () => {
            if (currentUser?.id === undefined)
                return;

            const rides = await ridesService.get(currentUser.id, year, month);

            setRides(rides);
        },
        [ridesService, currentUser, year, month]
    );

    useEffect(() => {
        async function loadData() {
            try {
                if (currentUser !== undefined)
                    return;

                const user = await graphService.getUserDetails();
                setCurrentUser(user);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [graphService, currentUser]);

    useEffect(() => {
        async function loadData() {
            try {
                if (projects !== undefined)
                    return;

                const data = await planningService.getProjects();
                setProjects(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [planningService, projects]);

    useEffect(() => {
        async function loadData() {
            try {
                if (users !== undefined)
                    return;

                const data = await peopleService.getAllFuncUsers();
                setUsers(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [peopleService, users]);

    useEffect(() => {
        const days = [];
        let currDate = new Date(year, month - 1, 1);
        while (currDate.getMonth() + 1 === month) {
            days.push(new Date(currDate));
            currDate.setDate(currDate.getDate() + 1);
        }
        setDaysInMonth(days);
    }, [year, month]);

    useEffect(() => {
        async function loadData() {
            try {
                await loadRides();
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [loadRides, currentUser]);

    async function afterSave() {
        try {
            setShowModal(false);

            await loadRides();
        }
        catch (err) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    function afterCancel() {
        setShowModal(false);
    }

    let modal = null;
    if (showModal && selectedRide && projects && users) {
        modal =
            <RideModal
                ride={selectedRide}
                afterSave={() => afterSave()}
                afterCancel={() => afterCancel()}
                projects={projects}
                users={users}
            />
    }

    return (
        <div className="container">
            <div className="card mb-3">
                <div className="card-body">
                    <form>
                        <div className=" row">
                            <label className="col-1 col-form-label" htmlFor="yearInput">Jaar</label>
                            <div className="col-2">
                                <input type="number" className="form-control" id="yearInput" onChange={(e) => setYear(parseInt(e.target.value))} value={year}></input>
                            </div>
                            <label className="col-1 col-form-label" htmlFor="monthInput">Maand</label>
                            <div className="col-2">
                                <input type="number" className="form-control" id="monthInput" onChange={(e) => setMonth(parseInt(e.target.value))} value={month}></input>
                            </div>
                        </div>
                    </form>
                </div>
            </div>

            <div className="card mb-3">
                <div className="card-body rides-days">
                    <div className="row">
                        <div className="col-2">
                            Datum
                        </div>
                        <div className="col-2">
                            Chauffeur / Passagier
                        </div>
                        <div className="col-2">
                            Werf
                        </div>
                        <div className="col-2">
                            Passagiers / Bestuurder
                        </div>
                        <div className="col-2">
                            km dubbel
                        </div>
                        <div className="col-2">
                            Uren
                        </div>
                    </div>
                    {daysInMonth?.map((day) => {
                        const ride = rides?.find(r => format(parseJSON(r.date ?? ""), "yyyy-MM-dd") === format(day, "yyyy-MM-dd"));
                        return (
                            <div className={isWeekend(day) ? "rides-weekend row" : "row"} key={format(day, "yyyy-MM-dd")}>
                                <div className="col-2">
                                    <Button color="link"
                                        onClick={() => {
                                            setShowModal(true);
                                            setSelectedRide(ride ?? {
                                                date: format(day, "yyyy-MM-dd") + "T00:00:00",
                                                personId: currentUser?.id,
                                                rideType: "Driver",
                                                hours: 8,
                                                kmOwnTransportationType: "OwnCar"
                                            } as RideModel);
                                        }}
                                    >{format(day, "dd/MM/yyy eee", { locale: nlBE })}</Button>
                                </div>
                                <div className="col-2">
                                    {ride?.rideType === "Driver" ? "Chauffeur" : ride?.rideType === "Passenger" ? "Passagier" : ""}
                                </div>
                                <div className="col-2">
                                    {ride?.projectCode}
                                </div>
                                <div className="col-2">
                                    {ride?.passengers?.map((p) => <>{p?.personName}<br /></>)}
                                    {ride?.driverPersonName}
                                </div>
                                <div className="col-2">
                                    {ride?.kmDouble}
                                </div>
                                <div className="col-2">
                                    {ride?.hours}
                                </div>
                            </div>
                        )
                    }
                    )}
                </div>
            </div>

            {modal}
        </div>
    );

}

export default (Rides);