import { useEffect, useMemo, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import "react-datepicker/dist/react-datepicker.css";
import { useMsal } from '@azure/msal-react';
import { toast } from 'react-toastify';
import RideModel from '../Models/RideModel';
import RidesService from '../Services/RidesService';
import nlBE from 'date-fns/locale/nl-BE';
import { format, parseJSON } from 'date-fns';
import ProjectModel from '../Models/ProjectModel';
import PlanningService from '../Services/PlanningService';
import PersonModel from '../Models/PersonModel';

interface RideModalProps {
    ride: RideModel;
    afterSave: Function;
    afterCancel: Function;
    projects: ProjectModel[];
    users: PersonModel[];
}

const RideModal = (props: RideModalProps) => {

    const { instance } = useMsal();

    const [visible, setVisible] = useState<boolean>(true);
    const [ride, setRide] = useState<RideModel>(props.ride);

    const [numberFields, setNumberFields] = useState<{ [index: string]: { valueAsString: string } }>({});

    const rideService = useMemo(() => new RidesService(instance), [instance]);
    const planningService = useMemo(() => new PlanningService(instance), [instance]);

    useEffect(() => {
        async function loadData() {
            try {
                if (ride.projectCode !== undefined)
                    return;

                const data = await planningService.getProjectForPersonOnDate(ride.personId ?? "", parseJSON(ride.date ?? ""));

                setRide({
                    ...ride,
                    projectCode: data
                });
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [planningService]);

    const modalToggle = () => {
        setVisible((visible) => { return !visible })

        props.afterCancel();
    }

    const modalSave = async () => {
        try {
            await rideService.upsert(ride);

            setVisible(false);
            props.afterSave();
        } catch (error) {
            toast.error('Fout bij het ophalen van de data.');
        }
    }

    const handleNumberChange = (value: string, field: string) => {

        value = value.replace(',', '.');

        const floatRegExp = new RegExp('^[+-]?([0-9]+([.][0-9]*)?|[.][0-9]+)$');

        if (value === '' || floatRegExp.test(value)) {
            setNumberFields({
                ...numberFields,
                [field]: { valueAsString: value }
            });
            setRide({
                ...ride,
                [field]: parseFloat(value)
            });
        }
    }

    return (
        <Modal isOpen={visible} toggle={modalToggle} size='xl' >
            <ModalHeader toggle={modalToggle}>Rit {format(parseJSON(ride.date ?? ""), "eeee dd/MM/yyy", { locale: nlBE })}</ModalHeader>
            <ModalBody>
                <form >
                    <div className="form-group">
                        <label className="" htmlFor="rideType">Type</label>
                        <select className="form-control" id="rideType" value={ride.rideType ?? ""} onChange={(e) => {
                            setRide({
                                ...ride,
                                rideType: e.currentTarget.value
                            });
                        }}>
                            <option value="Driver">Chauffeur</option>
                            <option value="Passenger">Passagier</option>
                        </select>
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="" htmlFor="projectCode">Werf</label>
                                <select className="form-control" id="projectCode" value={ride.projectCode ?? ""} onChange={(e) => {
                                    setRide({
                                        ...ride,
                                        projectCode: e.currentTarget.value
                                    });
                                }}>
                                    {
                                        props.projects.map((p) => {
                                            return (
                                                <option value={p.code} key={p.code}>{p.code}</option>
                                            );
                                        })
                                    }
                                </select>
                            </div>
                        </div>
                        {
                            ride.rideType === "Driver" ?
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="" htmlFor="passenger">Passagiers</label>
                                        {ride.passengers?.map((passenger, index) => {
                                            return (
                                                <select className="form-control" value={passenger.personId ?? ""} onChange={(e) => {
                                                    const newPassengers = [...ride.passengers ?? []];
                                                    newPassengers[index].personId = e.currentTarget.value;
                                                    if (e.currentTarget.value === "") {
                                                        newPassengers.splice(index, 1);
                                                    }
                                                    setRide({
                                                        ...ride,
                                                        passengers: newPassengers
                                                    });
                                                }}>
                                                    <option value=""></option>
                                                    {
                                                        props.users.map((u) => {
                                                            return (
                                                                <option value={u.id} key={u.id}>{u.name}</option>
                                                            );
                                                        })
                                                    }
                                                </select>
                                            );
                                        })}
                                        <select className="form-control" id="passenger" value={""} onChange={(e) => {
                                            const newPassengers = [...ride.passengers ?? []];
                                            newPassengers.push({ personId: e.currentTarget.value });
                                            setRide({
                                                ...ride,
                                                passengers: newPassengers
                                            });
                                        }}>
                                            <option value=""></option>
                                            {
                                                props.users.map((u) => {
                                                    return (
                                                        <option value={u.id} key={u.id}>{u.name}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>
                                </div> :
                                <div className="col-4">
                                    <div className="form-group">
                                        <label className="" htmlFor="driverPersonId">Bestuurder</label>
                                        <select className="form-control" id="driverPersonId" value={ride.driverPersonId} onChange={(e) => {
                                            setRide({
                                                ...ride,
                                                driverPersonId: e.currentTarget.value
                                            });
                                        }}>
                                            {
                                                props.users.map((u) => {
                                                    return (
                                                        <option value={u.id} key={u.id}>{u.name}</option>
                                                    );
                                                })
                                            }
                                        </select>
                                    </div>
                                </div>
                        }
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="" htmlFor="kmDouble">km dubbel (magazijn - werf)</label>
                                <input className="form-control" id="kmDouble"
                                    value={numberFields["kmDouble"]?.valueAsString ?? ride.kmDouble ?? ""}
                                    onChange={(e) => {
                                        handleNumberChange(e.currentTarget.value, "kmDouble");
                                    }} />
                            </div>
                        </div>
                        {ride.rideType === "Passenger" ? <>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="" htmlFor="kmOwnTransportationDouble">km eigen vervoer (thuis -&gt; werf -&gt; thuis)</label>
                                    <input className="form-control" id="kmOwnTransportationDouble"
                                        value={numberFields["kmOwnTransportationDouble"]?.valueAsString ?? ride.kmOwnTransportationDouble ?? ""}
                                        onChange={(e) => {
                                            handleNumberChange(e.currentTarget.value, "kmOwnTransportationDouble");
                                        }} />
                                    <select className="form-control" id="kmOwnTransportationType"
                                        value={ride.kmOwnTransportationType ?? ""}
                                        onChange={(e) => {
                                            setRide({
                                                ...ride,
                                                kmOwnTransportationType: e.currentTarget.value
                                            });
                                        }}>
                                        <option value="OwnCar">Eigen wagen</option>
                                        <option value="Bike">Fiets</option>
                                    </select>
                                </div>
                            </div>
                            <div className="col-4">
                                <div className="form-group">
                                    <label className="" htmlFor="kmHomeWarehouseDouble">km (thuis -&gt; magazijn -&gt; thuis</label>
                                    <input className="form-control" id="kmHomeWarehouseDouble"
                                        value={numberFields["kmHomeWarehouseDouble"]?.valueAsString ?? ride.kmHomeWarehouseDouble ?? ""}
                                        onChange={(e) => {
                                            handleNumberChange(e.currentTarget.value, "kmHomeWarehouseDouble");
                                        }} />
                                </div>
                            </div>
                        </> : <></>
                        }
                    </div>
                    <div className="row">
                        <div className="col-4">
                            <div className="form-group">
                                <label className="" htmlFor="hours">Uren</label>
                                <input className="form-control" id="hours"
                                    value={numberFields["hours"]?.valueAsString ?? ride.hours ?? ""}
                                    onChange={(e) => {
                                        handleNumberChange(e.currentTarget.value, "hours");
                                    }} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="" htmlFor="hrPlus">Hr +</label>
                                <input className="form-control" id="hrPlus"
                                    value={numberFields["hrPlus"]?.valueAsString ?? ride.hrPlus ?? ""}
                                    onChange={(e) => {
                                        handleNumberChange(e.currentTarget.value, "hrPlus");
                                    }} />
                            </div>
                        </div>
                        <div className="col-4">
                            <div className="form-group">
                                <label className="" htmlFor="hrMinus">Hr -</label>
                                <input className="form-control" id="hrMinus"
                                    value={numberFields["hrMinus"]?.valueAsString ?? ride.hrMinus ?? ""}
                                    onChange={(e) => {
                                        handleNumberChange(e.currentTarget.value, "hrMinus");
                                    }} />
                            </div>
                        </div>
                    </div>
                </form>
            </ModalBody>
            <ModalFooter>
                <Button color="primary" onClick={modalSave}>Bewaren</Button>{' '}
                <Button color="secondary" onClick={modalToggle}>Sluiten</Button>
            </ModalFooter>
        </Modal >

    );

}

export default RideModal;