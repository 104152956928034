import { IPublicClientApplication } from "@azure/msal-browser";
import { apiLoginRequest } from "../Config";
import RideModel from "../Models/RideModel";

export default class RidesService {

    msalInstance: IPublicClientApplication;

    constructor(msalInstance: IPublicClientApplication) {
        this.msalInstance = msalInstance;
    }

    public async get(personId: string | null, year: number | null, month: number | null): Promise<RideModel[]> {

        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        const data = await fetch(`${baseUrl}/api/Rides?year=${year}&personid=${personId}&month=${month}`, {
            method: 'get',
            headers: new Headers({
                'Authorization': 'Bearer ' + accessToken
            })
        }).then(function (response) {
            return response.json();
        });

        return data;
    }

    public async upsert(data: RideModel) {
        const baseUrl = process.env.REACT_APP_API_URL;
        const accessToken = await this.acquireAccessToken();

        await fetch(`${baseUrl}/api/Rides`, {
            method: "post",
            headers: new Headers({
                "Authorization": "Bearer " + accessToken
            }),
            body: JSON.stringify(data)
        });
    }

    private async acquireAccessToken() {
        const account = this.msalInstance.getActiveAccount();
        if (!account) {
            throw Error("No active account! Verify a user has been signed in and setActiveAccount has been called.");
        }

        const authResult = await this.msalInstance.acquireTokenSilent({
            ...apiLoginRequest,
            account: account
        });

        return authResult.accessToken;
    };
}