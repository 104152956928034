import { useCallback, useEffect, useMemo, useState } from "react";
import { useMsal } from "@azure/msal-react";
import { toast } from "react-toastify";
import RidesService from "../Services/RidesService";
import RideModel, { RidePassengerModel } from "../Models/RideModel";
import PeopleService from "../Services/PeopleService";
import PersonModel from "../Models/PersonModel";
import DataTable from "../Components/DataTable";
import { ColumnDef, createColumnHelper } from "@tanstack/react-table";
import { format, parseJSON } from "date-fns";
import { nlBE } from "date-fns/locale";

const columnHelper = createColumnHelper<RideModel>();

const columns = [
    columnHelper.accessor('personName', {
        header: 'Naam',
        cell: props => props.getValue(),
        size: 200
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('date', {
        header: 'Datum',
        cell: props => format(parseJSON(props.getValue() ?? ""), "dd/MM/yyy", { locale: nlBE }),
        size: 100
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('rideType', {
        header: 'Chauffeur / Bestuurder',
        cell: props => props.getValue() === "Driver" ? "Chauffeur" : props.getValue() === "Passenger" ? "Passagier" : props.getValue(),
        size: 100
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('projectCode', {
        header: 'Werf',
        cell: props => props.getValue(),
        size: 130
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('passengers', {
        header: 'Passagiers',
        cell: props => {
            return (
                <>
                    {props.getValue()?.map((p: RidePassengerModel) => {
                        return (<>{p?.personName}, </>);
                    })}
                </>)
        },
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('driverPersonName', {
        header: 'Bestuurder',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('kmDouble', {
        header: 'km dubbel',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('kmOwnTransportationType', {
        header: 'eigen transport',
        cell: props => props.getValue() === "OwnCar" ? "Eigen wagen" : props.getValue() === "Bike" ? "Fiets" : props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('kmOwnTransportationDouble', {
        header: 'km thuis -> werf -> thuis ',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('kmHomeWarehouseDouble', {
        header: 'km thuis -> magazijn -> thuis ',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('hours', {
        header: 'uren',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('hrPlus', {
        header: 'Hr +',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
    columnHelper.accessor('hrMinus', {
        header: 'Hr -',
        cell: props => props.getValue(),
        size: 70
    }) as ColumnDef<unknown, any>,
];

const RidesOverview = () => {

    const { instance } = useMsal();

    const ridesService = useMemo(() => new RidesService(instance), [instance]);
    const peopleService = useMemo(() => new PeopleService(instance), [instance]);

    const [year, setYear] = useState<number>((new Date()).getFullYear());
    const [month, setMonth] = useState<number>((new Date()).getMonth() + 1);
    const [personId, setPersonId] = useState<string>("");
    const [rides, setRides] = useState<RideModel[]>();
    const [users, setUsers] = useState<PersonModel[]>();

    const loadRides = useCallback(
        async () => {
            if (personId === "")
                setRides([]);

            const rides = await ridesService.get(personId, year, month);

            setRides(rides);
        },
        [ridesService, personId, year, month]
    );

    useEffect(() => {
        async function loadData() {
            try {
                if (users !== undefined)
                    return;

                const data = await peopleService.getAllFuncUsers();
                setUsers(data);
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [peopleService, users]);

    useEffect(() => {
        async function loadData() {
            try {
                await loadRides();
            }
            catch (err) {
                toast.error('Fout bij het ophalen van de data.');
            }
        }

        loadData();
    }, [loadRides]);

    if (rides && columns && columns.length > 0) {
        return (
            <div className="container-fluid">
                <div className="card mb-3">
                    <div className="card-body">
                        <form>
                            <div className=" row">
                                <label className="col-1 col-form-label" htmlFor="yearInput">Jaar</label>
                                <div className="col-2">
                                    <input type="number" className="form-control" id="yearInput" onChange={(e) => setYear(parseInt(e.target.value))} value={year}></input>
                                </div>
                                <label className="col-1 col-form-label" htmlFor="monthInput">Maand</label>
                                <div className="col-2">
                                    <input type="number" className="form-control" id="monthInput" onChange={(e) => setMonth(parseInt(e.target.value))} value={month}></input>
                                </div>
                                <label className="col-1 col-form-label" htmlFor="personId">Persoon</label>
                                <div className="col-2">
                                    <select className="form-control" id="personId" onChange={(e) => setPersonId(e.target.value)} value={personId}>
                                        {
                                            users?.map((u) => {
                                                return (
                                                    <option value={u.id} key={u.id}>{u.name}</option>
                                                );
                                            })
                                        }
                                    </select>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <DataTable
                    data={rides}
                    columns={columns}
                    height="65vh"
                />
            </div>
        );
    }

    return (
        <></>
    );

}

export default (RidesOverview);